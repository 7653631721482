<template>

<app-content>

	<app-content-head title="Profile" subtitle="Manage your profile and preferences." />

	<app-content-body class="profile">

		<div class="profile-main">

			<com-profile />
			<com-account />
			<com-password />

		</div>

		<div class="profile-side">

			<com-settings />

		</div>

	</app-content-body>

</app-content>

</template>

<script>

export default {

	components: {
		'com-profile': () => import('./profile/Profile'),
		'com-account': () => import('./profile/Account'),
		'com-password': () => import('./profile/Password'),
		'com-settings': () => import('./profile/Settings')
	}

}

</script>

<style scoped>

.profile {
	display: flex;
	width: 100%;
}

.profile-main {
	flex-grow: 1;
}

.profile-side {
	width: 320px;
	flex-shrink: 0;
	margin-left: 40px;
}

.profile-side >>> .box {
	margin-bottom: 30px;
}

.is-mobile .profile {
	flex-direction: column;
}

.is-mobile .profile-side {
	width: 100%;
	margin-left: 0px;
}

</style>